import React from "react";

// Styles
import { StyledImage } from "./BottomLine.styled";

// Assets
import bottomline from "../../images/hero-bottomline.png"


const BottomLine = () => (
    <StyledImage className="bottomline" src={bottomline} alt=""/>
)

export default BottomLine