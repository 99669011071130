import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
  ${tw`relative block`}

  .video-wrap{
    ${tw`relative text-center flex flex-col items-center justify-start sm:justify-center overflow-hidden`}
    aspect-ratio: 16/9
    min-height: 100vh;

    @media (max-width: 639px){
      /*height: calc(100vh - 80px);*/
    }

    video{
      ${tw`absolute`}

      &.desktop-video{
        ${tw`hidden sm:block w-full`}

        min-width: 100vw;
        min-height: 100vh;
        aspect-ratio: 16/9;
        width: auto;
      }

      &.mobile-video{
        ${tw`block sm:hidden`}
        min-width: 100%;
        min-height: 100%;
        aspect-ratio: 9/16;
        object-fit: cover;
        width: 100%;
      }
    }


    img{ 
      ${tw`block mx-auto max-w-full`} 
      &.tkg-circle-logo{ ${tw`my-12 max-w-full`} }
      &.choose-logo{ ${tw`ml-0 mr-auto sm:mx-auto`} }
    }
    
    .content-frame{
      ${tw`w-full mx-auto relative text-left sm:text-center px-4 sm:px-8 lg:px-0 box-border`}
      max-width: 940px;

      p{
        ${tw`my-8`}
      }
    }

    .block1, .block2{
      ${tw`opacity-0`}
    }

    .cta{
      ${tw`
        bg-lightorange text-white border-white   hover:bg-white hover:text-lightorange shadow   transition-all
        block text-center border-0 border-solid border-8 px-8 py-1 mt-8 mb-12 mx-auto rounded-full font-bold no-underline
      `}
      width: fit-content;
    }
  }
`