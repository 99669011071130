import React, { useEffect, useContext } from 'react';
import Layout from "../components/Layout/Layout"
import Hero from "../components/Home/Hero/Hero"
import Seo from "../components/Seo/Seo"

import { useHomeQuery } from "../hooks/useHomeQuery"
import PetauriBanner from "../components/Home/PetauriBanner/PetauriBanner"
import VideoPresentationBlock from "../components/Home/VideoPresentationBlock/VideoPresentationBlock"
import Interstitial from '../components/Home/Interstitial/Interstitial';
import {GlobalStateContext, GlobalDispatchContext } from "../../src/context/GlobalContextProvider"

const IndexPage = ({ children }) => {

  const data = useHomeQuery();
  const heroBlocks = Object.values(data.heroBlocks);

  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  useEffect(() => {
    return () => {
        dispatch({
          type: 'MODAL_CONTENT',
          payload: null
        })
        document.body.classList.remove('modal-open')
    }
  },[dispatch]); 

  return(
    <Layout>
      <Seo title="Home" />

      <PetauriBanner/>

      <VideoPresentationBlock data={data.videoPresentationBlock}/>

      {/*heroBlocks.map((item, index) => (
        <Hero key={index} title={item.title} copy={item.copy} image={item.image} ctaButton={item.ctaButton} />
      ))*/}
  
      <form name="resumes" data-netlify data-netlify-honeypot="bot-field" hidden>
        <input type="hidden" name="form-name" value="resumes" />
        <input type="text" required name="name" />
        <input type="text" required name="position" />
        <input id="resume-file" name="file" type="file" required accept=".doc, .docx, .pdf"/>
      </form>

      {state !== undefined && state.modalContent !== null
        ? (<Interstitial/> )
        : (null)
      }

    </Layout>
  )

}

export default IndexPage
