import React, {useContext, useEffect, useRef} from "react";
import BottomLine from "../../BottomLine/BottomLine"
import {ModalWrapper} from "./Interstitial.styled"
import {GlobalDispatchContext,GlobalStateContext } from "../../../context/GlobalContextProvider"
import gsap from 'gsap';

import closeButton from "../../../images/close-btn.svg"


const Interstitial = () => {

    const dispatch = useContext(GlobalDispatchContext)
    const state = useContext(GlobalStateContext)
    const modalWrapper = useRef();
    const $ = gsap.utils.selector(modalWrapper);

    const closeModal = (e)=> {
        if(!e.target.classList.contains('close-trigger')) return;

        let tl = new gsap.timeline()
        tl.to($('.modal-box'), {opacity:0, duration:0.2})
        tl.to(modalWrapper.current, {opacity:0, duration:0.2, onComplete:()=>{
            dispatch({type: 'MODAL_CONTENT', payload: null})
            document.body.classList.remove('modal-open')
        }}, "+=0.0")
    }

    useEffect(() => {
        document.body.classList.add('modal-open')

        //Animation
        console.log('useEffect of interstitial')
        let tl = new gsap.timeline()
        tl.from(modalWrapper.current, {opacity:0, duration:0.2})
        tl.from($('.modal-box'), {opacity:0, duration:0.2})
        tl.from($('.text-wrap'), {opacity:0}, "-=0.2")

        // cleanup function will be called when component is removed
        return () => {
        }
  
    }, []);

    return(
        <ModalWrapper className="close-trigger" ref={modalWrapper} onClick={closeModal}>
            <div className="modal-box">

                <button className="btn-close close-trigger" onClick={closeModal}>
                    <img src={closeButton} alt=""/>
                </button>
                
                <div className="text-wrap text-center">
                    <p>You are leaving thekinetixgroup.com website
                    The&nbsp;PROCEED&nbsp;button below will take you 
                    to&nbsp;PetauriHealth.com
                    </p>
                    <div>
                        <button className="close-trigger smooth" onClick={closeModal}>CANCEL</button>
                        <a href={state.modalContent.link} target="_blank" className="close-trigger smooth" onClick={closeModal}>PROCEED</a>
                    </div>
                </div>
                <BottomLine/>
            </div>
        </ModalWrapper>
    )
}

export default Interstitial;