import { useStaticQuery, graphql } from "gatsby"

export const useHomeQuery = ()=> {


    const data = useStaticQuery(graphql`
    query ACF {
        wpPage(title: {eq: "HOME"}) {
          ACF_HomePage {

            videoPresentationBlock {
              desktopVideo {
                guid
              }
              desktopVideoPoster{
                localFile{
                  publicURL
                }
              }
              mobileVideo {
                guid
              }
              mobileVideoPoster{
                localFile{
                  publicURL
                }
              }
              endframeContent {
                animatedTitle
                mainCopy
                cta {
                  text
                  url
                }
              }
            }

            heroBlocks {
              hero1 {
                copy
                title
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                ctaButton {
                  textToDisplay
                  url
                  cssclass
                }
              }
              hero2 {
                copy
                title
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                ctaButton {
                  textToDisplay
                  url
                  cssclass
                }
              }
            }
          }
        }
      }     
    `)
    return data.wpPage.ACF_HomePage;
}