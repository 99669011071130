import tw, { styled } from "twin.macro"

export const ModalWrapper = styled.div`
    ${tw`fixed w-full flex justify-center items-center px-4 sm:px-6 box-border`}
    height: 90vh;
    background: rgba(0,0,0,0.5);

    .btn-close{
        ${tw`absolute m-0 p-0 right-5 top-5 bg-transparent outline-none border-none cursor-pointer`}
        img{
            pointer-events: none;
        }
    }

    .modal-box{
        ${tw`
            relative flex flex-col
            bg-white overflow-hidden justify-center
            p-6
        `}
        box-shadow: 0 6px 18px rgb(0 0 0 / 30%);
        max-width: 680px;
        max-height: 580px;

        .text-wrap{
            ${tw`px-8 overflow-auto flex flex-col text-center`}

            h3{
                ${tw`text-4xl uppercase mb-0`}
            }
            h4{
                ${tw`text-2xl text-lightorange my-0`}
            }

            a, button{
                ${tw`
                    underline bg-transparent border-0 cursor-pointer
                    text-lg sm:text-2xl
                    text-black visited:text-black
                    p-2 sm:p-4
                `}
            }

            .smooth{
                -webkit-font-smoothing: antialiased;
            }
        }
    }
`