import React, { useEffect, useRef, useState } from 'react';
import { Wrapper } from './VideoPresentationBlock.styles'
import gsap from 'gsap';
import BottomLine from '../../BottomLine/BottomLine'
import TypeWriteLine from '../../TypeWriteLine/TypeWriteLine'

import circleLogo from "../../../images/Petauri_KinectMax_logoCombo.png"
import chooseLogo from "../../../images/choose-logo.svg"
import { Link } from 'gatsby';


const VideoPresentationBlock = ({data}) => {
   
    const videoWrap = useRef();
    const $ = gsap.utils.selector(videoWrap);

    const [animatedTitle, setAnimatedTitle] = useState(" ");

    const showEndFrame = (e)=>{
        setAnimatedTitle( data.endframeContent.animatedTitle )
        console.log(animatedTitle);

        setTimeout(()=>{            
            const tl = new gsap.timeline();
            tl.to($('.block1'), {duration:1, opacity:1}, '-=0')
    
            $('.desktop-video')[0].currentTime = $('.desktop-video')[0].duration;
            $('.mobile-video')[0].currentTime = $('.desktop-video')[0].duration;    
        }, 10)
    }
    
    return(
        <Wrapper>
            <div ref={videoWrap} className='video-wrap'>

                <video 
                    className='desktop-video' 
                    src={data.desktopVideo.guid} 
                    onEnded={showEndFrame} 
                    poster={data.desktopVideoPoster.localFile.publicURL}
                    autoPlay muted playsInline
                />

                <video 
                    className='mobile-video' 
                    src={data.mobileVideo.guid} 
                    onEnded={showEndFrame}
                    poster={data.mobileVideoPoster.localFile.publicURL}
                    autoPlay muted  playsInline
                />

                <div className='content-frame block1'>
                    <img src={circleLogo} width={400} className='tkg-circle-logo' alt="Petauri Kinect | Petauri Max"/>
                    <img src={chooseLogo} className='choose-logo' alt="CHOOSE"/>
                    <TypeWriteLine copy={animatedTitle}/>
                    <div dangerouslySetInnerHTML={{__html: data.endframeContent.mainCopy}}></div>

                    <Link className='cta' to={data.endframeContent.cta.url}>{data.endframeContent.cta.text}</Link>
                </div>
            </div>
            <BottomLine/>
        </Wrapper>
    )
}

export default VideoPresentationBlock