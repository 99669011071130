import React, { useLayoutEffect, useRef } from 'react';
import {getImage} from "gatsby-plugin-image"
import { Wrapper, BlockContent, MobileBlock, ImageContainer, GatsbyImageStyled } from './Hero.styles'
import { Link } from "gatsby"
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import chooseLogo from "../../../images/choose-logo.svg"
import bottomline from "../../../images/hero-bottomline.png"


const Hero = ({image, title, copy, ctaButton}) => {

    const imageData = getImage(image.localFile)
    const animatedTitle = useRef();
    const cursor = useRef();      

    // Runs when component is layed out on Screen.
    useLayoutEffect(() => {
        
        /* TYPING ANIMATION */
        const textToAnimate = animatedTitle.current.innerText;
        let currIndex = 0;
        let typingInterval;

        animatedTitle.current.innerHTML = "";
        animatedTitle.current.appendChild(cursor.current);

        gsap.registerPlugin(ScrollTrigger);

        const typing = gsap.to(animatedTitle.current, {
            scrollTrigger: {
              trigger: animatedTitle.current,
              toggleActions: 'play none none pause',
              start: '100% bottom',
            },
            duration: 0.5,
            opacity: 1,
            onComplete: ()=> {
                typingInterval = setInterval(()=>{
                    currIndex++;
                    animatedTitle.current.innerText = textToAnimate.substr(0, currIndex);
                    animatedTitle.current.appendChild(cursor.current);
                    if(currIndex === textToAnimate.length+1) clearInterval(typingInterval);
                }, 70)
            }
        })

        const cursorBlink = gsap.timeline({repeat:-1})
        .to(cursor.current, {duration:0.3, opacity:0}, "+=0.6")
        .to(cursor.current, {duration:0.2, opacity:1}, "+=0")


        // cleanup function will be called when component is removed
        return () => {
            clearInterval(typingInterval);
            typing.kill();
            cursorBlink.kill();
        };


    }, []);    

    return(
        <Wrapper>
            <ImageContainer>
                <GatsbyImageStyled image={imageData} alt="Hero Image"/>
                <BlockContent>
                    <img className="chooseLogo" src={chooseLogo} alt="Choose"/>
                    <h2 ref={animatedTitle}>
                        {title}
                        <span className='cursor' ref={cursor}></span>
                    </h2>
                    <p className="main">{copy}</p>
                    <Link className={`cta ${ctaButton.cssclass}`} to={ctaButton.url}>{ctaButton.textToDisplay}</Link>
                </BlockContent>
            </ImageContainer>
            
            <MobileBlock>
                <p className="main">{copy}</p>
                <Link className={`cta ${ctaButton.cssclass}`} to={ctaButton.url}>{ctaButton.textToDisplay}</Link>
            </MobileBlock>

            <img className="bottomline" src={bottomline} alt=""/>
        </Wrapper>
    )
}

export default Hero